@import 'fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: 'Neue Haas Unica Pro', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.masonry-css-grid {
	padding: 0 26px;
}
.card_columns_masonry {
	padding: 0 15px;
}
