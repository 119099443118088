/* This stylesheet generated by Transfonter (http://transfonter.org) on July 1, 2016 4:31 PM */

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-UltraLight.ttf')
		format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Thin.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Medium.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Heavy.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Black.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: block;
}

/* @font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-XBlack.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: block;
} */

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-UltLightIt.ttf')
		format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-XBlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-ThinItalic.ttf')
		format('truetype');
	font-weight: 100;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-MediumIt.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-BoldItalic.ttf')
		format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('./assets/fonts/NeueHaasUnicaPro-HeavyIt.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
	font-display: block;
}
